import React, { Component } from "react";
import MessageHeader from "./MessageHeader";
import MessageList from "./MessageList";
import NewMessageInput from "./NewMessageInput";
import "./Message.css";

class Messages extends Component {
  render() {
    return (
      <div className="message ">
        <MessageHeader />
        <MessageList />
        <NewMessageInput />
      </div>
    );
  }
}

export default Messages;
