import {
  INIT_MESSAGE_COUNT,
  INIT_TOTAL_MESSAGE_COUNT,
  UPDATE_ONE_MESSAGE_COUNT,
  INCREASE_TOTAL_MESSAGE_COUNT,
  RESET_TOTAL_MESSAGE_COUNT,
  RESET_SELECTED_MESSAGE_COUNT,
} from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INIT_MESSAGE_COUNT:
      return {
        ...state,
        ...payload,
      };
    case INIT_TOTAL_MESSAGE_COUNT:
      return {
        ...state,
        totalCount: 0 + parseInt(payload) || 0,
      };
    case UPDATE_ONE_MESSAGE_COUNT:
      return {
        ...state,
        [payload]:
          state[payload] && state[payload] >= 0
            ? state[payload] + 1
            : 1,
      };
    case RESET_SELECTED_MESSAGE_COUNT:
      return {
        ...state,
        totalCount:
          state.totalCount - state[payload] > 0
            ? state.totalCount - state[payload]
            : 0,
        [payload]: 0,
      };
    case INCREASE_TOTAL_MESSAGE_COUNT:
      return {
        ...state,
        totalCount: state.totalCount + 1 || 1,
      };
    case RESET_TOTAL_MESSAGE_COUNT:
      return {
        ...state,
        totalCount: 0,
      };
    default:
      return state;
  }
};
