import {
  USER_LOADING_STARTED,
  USER_LOADING_MORE_STARTED,
  UPDATE_USERS_LIST,
  SET_LAST_CREATED_AT_TIME_USERS,
  LOAD_MORE_USERS_LIST,
  TOGGLE_SHOW_UNREAD_LIST,
} from "../constants/types";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADING_STARTED:
      return {
        ...state,
        loadingUsers: true,
      };
    case USER_LOADING_MORE_STARTED:
      return {
        ...state,
        loadingMoreUsers: true,
      };
    case UPDATE_USERS_LIST:
      return {
        ...state,
        users: payload,
        loadingUsers: false,
      };
    case LOAD_MORE_USERS_LIST:
      return {
        ...state,
        users: [...state.users, ...payload],
        loadingMoreUsers: false,
      };
    case SET_LAST_CREATED_AT_TIME_USERS:
      return {
        ...state,
        lastCreatedAtTime: payload,
      };
    case TOGGLE_SHOW_UNREAD_LIST:
      return {
        ...state,
        showUnreadList: !state.showUnreadList,
      };

    default:
      return state;
  }
};
