import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dateFormat } from "../../utils/formatDate";
import MessageCard from "./MessageCard";
import { loadMoreMessageList } from "../../actions/update-message-list.action";
import Spinner from "../dashboard/Spinner";
import { Button, IconButton } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

class MessageList extends Component {
  componentDidMount() {
    this.handleScrollToLastMessage();
  }
  componentDidUpdate() {
    if (this.props.messages.fetchedMore && !this.props.messages.hasMore) {
      this.handleScrollToFirstMessage();
    } else if (this.props.messages.fetchedMore && this.props.messages.hasMore) {
      this.handleScrollOnLoadMore(this.props.messages.scrollTo);
    } else {
      this.handleScrollToLastMessage();
    }
  }
  handleScrollToLastMessage = () =>
    (this.messageBodyEl.scrollTop = this.messageBodyEl.scrollHeight);
  handleScrollToFirstMessage = () =>
    (this.messageBodyEl.scrollTop = this.messageBodyEl.scrollTop);
  handleScrollOnLoadMore = (scrollTo) => {
    this.messageBodyEl.scrollTop = scrollTo;
  };

  fetchMoreData = () => {
    let lastCreatedTime =
      this.props.messages && this.props.messages.lastCreatedTime;
    let id =
      this.props.messages &&
      this.props.messages.selectedChatUserDetails &&
      this.props.messages.selectedChatUserDetails[0] &&
      this.props.messages.selectedChatUserDetails[0].userId;
    let scrollTo = this.messageBodyEl.scrollHeight - 450;
    this.props.loadMoreMessageList(id, lastCreatedTime, scrollTo);
  };
  render() {
    const { activeUser, messages } = this.props;
    return (
      <div
        className="message__body"
        ref={(component) => (this.messageBodyEl = component)}
      >
        {messages.loadingMessages && <Spinner />}
        {!messages.loadingMessages && (
          <>
            {messages.hasMore && (
              <div className="message_bodyButton" onClick={this.fetchMoreData}>
                <Button variant="contained">Show more messages</Button>
              </div>
            )}
            {messages.fetchedMore && !messages.hasMore && (
              <div className="message_bodyButton">
                <p>This is the end of the conversation</p>
              </div>
            )}
            {messages.messageList &&
              messages.messageList.map((chat) => (
                <MessageCard key={chat.key} message={chat} />
              ))}
            {messages.messageList &&
              messages.fetchedMore &&
              this.messageBodyEl.scrollHeight - this.messageBodyEl.scrollTop >
                500 && (
                <IconButton onClick={this.handleScrollToLastMessage}>
                  <ArrowDownwardIcon />
                </IconButton>
              )}
          </>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    activeUser: state.activeUser,
    messages: state.messages,
  }),
  { loadMoreMessageList }
)(MessageList);
