import React from "react";

export const RightArrow = (props) => (
  <svg width={30} height={30} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill || "#ffff"}
      d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export const ImageUploadLogo = (props) => (
  <svg width={30} height={30} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill || "#ffff"}
      d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
export const VideoUploadLogo = (props) => (
  <svg width={30} height={30} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill || "#ffff"}
      d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
export const AudioUploadLogo = (props) => (
  <svg width={30} height={30} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill || "#ffff"}
      d="M12 3v9.28c-.47-.17-.97-.28-1.5-.28C8.01 12 6 14.01 6 16.5S8.01 21 10.5 21c2.31 0 4.2-1.75 4.45-4H15V6h4V3h-7z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
export const FileUploadLogo = (props) => (
  <svg width={30} height={30} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill || "#ffff"}
      d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
export const DefaultUser = (props) => (
  <svg
    width={props.width || 30}
    height={props.height || 30}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.fill || "#ffff"}
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
export const EmojiIcon = (props) => (
  <svg
    width={props.width || 30}
    height={props.height || 30}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.fill || "#000"}
      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
export const SendIcon = (props) => (
  <svg
    width={props.width || 15}
    height={props.height || 15}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={props.fill || "#919191"}
      d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export const ApnaTimeLogo = (props) => (
  <svg width={56} height={56} viewBox="0 0 56 56" {...props}>
    <g data-name="Group 1" fill="#fff">
      <path
        data-name="Path 1"
        d="M11.23 0h33.54A11.228 11.228 0 0156 11.23v33.54A11.228 11.228 0 0144.77 56H11.23A11.228 11.228 0 010 44.77V11.23A11.228 11.228 0 0111.23 0z"
      />
      <path
        data-name="Path 2"
        d="M11.23 0h33.54A11.228 11.228 0 0156 11.23v33.54A11.228 11.228 0 0144.77 56H11.23A11.228 11.228 0 010 44.77V11.23A11.228 11.228 0 0111.23 0z"
      />
    </g>
    <path
      data-name="Path 3"
      d="M44.77 56h-7.437v-7h17.843a11.232 11.232 0 01-10.4 7z"
      fill="#ffd166"
      fillRule="evenodd"
    />
    <path
      data-name="Path 4"
      d="M18.667 49v7h-7.438a11.232 11.232 0 01-10.4-7z"
      fill="#2bb793"
      fillRule="evenodd"
    />
    <path
      data-name="Path 5"
      d="M18.667 49h18.667v7H18.667z"
      fill="#83bde4"
      fillRule="evenodd"
    />
    <path
      data-name="Path 6"
      d="M15.333 30.26a1.9 1.9 0 00.142.84.909.909 0 00.505.414l-.809 2.568a4.964 4.964 0 01-1.78-.414 2.685 2.685 0 01-1.132-1.082A3.637 3.637 0 019 34.163a3.53 3.53 0 01-2.579-.96 3.316 3.316 0 01-.98-2.478 3.166 3.166 0 011.334-2.75 6.654 6.654 0 013.883-.95h.768v-.263a1.428 1.428 0 00-.323-1.092 1.943 1.943 0 00-1.234-.283 6.617 6.617 0 00-1.244.151 13.01 13.01 0 00-1.547.415l-.87-2.548a12.257 12.257 0 014.268-.849 5.666 5.666 0 013.7.98 3.725 3.725 0 011.153 3v3.724zm-5.1 1.092a1.321 1.321 0 00.678-.182 1.63 1.63 0 00.515-.465v-1.6h-.424a2.086 2.086 0 00-1.254.3 1.086 1.086 0 00-.405.93 1.07 1.07 0 00.243.738.823.823 0 00.647.273zm13.67-8.8a3.409 3.409 0 013.024 1.516 7.556 7.556 0 011.02 4.247 7.955 7.955 0 01-.535 3A4.871 4.871 0 0125.9 33.4a3.585 3.585 0 01-2.255.758 3.226 3.226 0 01-2.507-1.072v4.854l-4 .4V22.96h3.559l.162 1.052A3.923 3.923 0 0122.3 22.9a4.036 4.036 0 011.607-.343zm-1.573 8.76q1.517 0 1.517-2.932a8.247 8.247 0 00-.162-1.881 1.563 1.563 0 00-.435-.86 1.105 1.105 0 00-.7-.212 1.641 1.641 0 00-1.415 1.011v4.126a1.8 1.8 0 00.546.576 1.227 1.227 0 00.647.172zm13.97-8.756a2.75 2.75 0 012.133.849 3.4 3.4 0 01.779 2.386v7.968h-4v-7.24a1.589 1.589 0 00-.152-.829.515.515 0 00-.455-.222q-.667 0-1.355 1.071v7.22h-4V22.96h3.5l.284 1.112a5.118 5.118 0 011.526-1.152 4.08 4.08 0 011.75-.364zm13.852 7.7a1.913 1.913 0 00.141.84.911.911 0 00.506.414l-.809 2.568a4.958 4.958 0 01-1.78-.414 2.685 2.685 0 01-1.132-1.082 3.639 3.639 0 01-3.256 1.577 3.527 3.527 0 01-2.578-.96 3.316 3.316 0 01-.98-2.478 3.166 3.166 0 011.334-2.75 6.653 6.653 0 013.882-.95h.769v-.263a1.424 1.424 0 00-.324-1.092 1.939 1.939 0 00-1.233-.283 6.608 6.608 0 00-1.244.151 12.959 12.959 0 00-1.547.415l-.87-2.548a12.246 12.246 0 014.267-.849 5.665 5.665 0 013.7.98 3.725 3.725 0 011.153 3zm-5.1 1.092a1.32 1.32 0 00.677-.182 1.63 1.63 0 00.516-.465v-1.6h-.425a2.09 2.09 0 00-1.254.3 1.085 1.085 0 00-.4.93 1.065 1.065 0 00.243.738.823.823 0 00.647.273z"
      fill="#4d3951"
    />
  </svg>
);
