import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import { Provider } from "react-redux";
import Login from "./components/auth/Login";
import MainLayout from "./components/dashboard/MainLayout";
import PageNotFound from "./components/dashboard/NotFound";
import { GetCurrentStorage } from "./utils/LocalStorage";
import store from "./store";
import ProtectedRoute from "./components/routing/ProtectedRoute";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Login} />
              <ProtectedRoute exact path="/message" component={MainLayout} />
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
