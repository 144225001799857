import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Button,
  IconButton,
  Badge,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ChatIcon from "@material-ui/icons/Chat";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ApnaTimeLogo } from "../../utils/Svg";
import UsersListUnread from "./UsersListUnread";
import Spinner from "../dashboard/Spinner";
import "./UsersList.css";
import { toggleUnreadList } from "../../actions/update-users-list.action";
import { logout } from "../../actions/auth.action";
import { GetCurrentStorage } from "../../utils/LocalStorage";
import UserChat from "./UserChat";

const CountBadge = withStyles((theme) => ({
  badge: {
    right: 4,
    backgroundColor: "#2bb793",
    color: "white",
    marginRight: 15,
  },
}))(Badge);

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 4,
    backgroundColor: "#2bb793",
    color: "white",
  },
}))(Badge);

class UsersList extends Component {
  state = {
    anchorEl: null,
  };
  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  toggleUnread = () => {
    this.props.toggleUnreadList();
  };
  resetCount = () => {
    this.props.resetTotalMessageCount();
  };
  handleLogout = () => {
    window.Applozic.AlCustomService.logout();
    this.props.logout();
    localStorage.clear();
    window.location.reload();
  };
  handhandleChange = (e) => {
    e.preventDefault();
  };

  renderList = () => {
    const { usersList } = this.props;
    const users = usersList?.users;
    if (usersList?.loadingUsers) {
      return <Spinner />;
    }
    if (!usersList?.loadingUsers && users && users.length > 0) {
      return users.map((user) => {
        let chatId = `${user?.userId}_${user?.to_user_id}`;
        return (
          <UserChat key={chatId} chat={user} />
        )
      });
    }
  };
  render() {
    const { usersList, newMessageCount, activeUser } = this.props;
    const users = usersList?.users;
    const showUnread = usersList?.showUnreadList;
    const totalCount = newMessageCount?.totalCount;
    const senderName = GetCurrentStorage("senderName");
    const mobile = GetCurrentStorage("mobile");

    return (
      <div className="sidebar">
        <div className="sidebar__header">
          <ApnaTimeLogo />
          <div className="sidebar__headerRight">
            {totalCount > 0 && (
              <CountBadge
                badgeContent={totalCount}
              />
            )}
            {!showUnread && (
              <IconButton onClick={() => this.toggleUnread()}>
                <ChatIcon />
              </IconButton>
            )}
            {showUnread && (
              <IconButton onClick={() => this.toggleUnread()}>
                <StyledBadge variant="dot" overlap="circle" badgeContent=" ">
                  <ChatIcon />
                </StyledBadge>
              </IconButton>
            )}
            <IconButton onClick={this.handleClick}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem>
                <div className="sidebar__headerAvatar">
                  <Avatar
                    alt={this.props.activeUser.full_name}
                    src={this.props.activeUser.profileUrl}
                  />
                </div>
              </MenuItem>
              <MenuItem>
                <div className="sidebar__headerMenu">
                  <p>{senderName}</p>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="sidebar__headerMenu">
                  <p> {`Mobile - ${mobile}`}</p>
                </div>
              </MenuItem>
              <MenuItem onClick={this.handleLogout}>
                <div className="sidebar__headerMenu">
                  <PowerSettingsNewIcon />
                  <p className="sidebar__headerLogout">Logout</p>
                </div>
              </MenuItem>
            </Menu>
          </div>
        </div>
        {!usersList?.showUnreadList && (
          <div className="sidebar__chat">{this.renderList()}</div>
        )}
        {usersList?.showUnreadList && <UsersListUnread />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeUser: state.activeUser,
  usersList: state.usersList,
  newMessageCount: state.newMessageCount
});

export default connect(mapStateToProps, { toggleUnreadList, logout })(UsersList);
