import { Get, OpenPost } from "../utils/api";
import history from "../utils/history";
import { storage } from "../firebase";
import _ from "lodash";
import {
  setFAQsClose,
  fetchFAQs,
  fetchJobCategories,
} from "./create-new-message.action";
import { AddDataToLocalStorage } from "../utils/LocalStorage";
import APIConfig from "../utils/APIConfig";
import {
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGOUT,
  CHANGE_USER_PROFILE_IMAGE,
} from "../constants/types";

// Load User
export const loadUser = () => (dispatch) => {
  Get(APIConfig.USER_PROFILE)
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res,
      });
      AddDataToLocalStorage("user", res);
      dispatch(changeUserProfileImage(res));
      dispatch(setFAQsClose());
      dispatch(fetchFAQs());
      dispatch(fetchJobCategories());
    })
    .catch((error) => console.error(error));
};

export const changeUserProfileImage = (userData) => (dispatch) => {
  storage
    .child(`${userData.photo_firebase_path}`)
    .getDownloadURL()
    .then((url) => {
      dispatch({
        type: CHANGE_USER_PROFILE_IMAGE,
        payload: url,
      });
      AddDataToLocalStorage("profileUrl", url);
    });
};

// Login User
export const login = (name, mobile, otp) => (dispatch) => {
  const data = {
    phone_number: mobile,
    otp: otp,
    platform: "web",
    phone_number_prefixed: `91${mobile}`,
  };
  OpenPost(APIConfig.LOGIN, data)
    .then((res) => {
      if (res && res.STATUS && res.STATUS === "SUCCESS") {
        AddDataToLocalStorage("userToken", res.key);
        AddDataToLocalStorage("senderName", name);
        AddDataToLocalStorage("mobile", mobile);
        AddDataToLocalStorage("isAuthenticated", true);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res,
        });
        dispatch(loadUser());
        history.push("/message");
      } else {
        localStorage.clear();
        window.location.reload();
      }
    })
    .catch((error) => console.error(error));
};

// Logout
export const logout = () => ({ type: LOGOUT });
