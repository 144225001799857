import {
  INIT_MESSAGE_COUNT,
  INIT_TOTAL_MESSAGE_COUNT,
  UPDATE_ONE_MESSAGE_COUNT,
  INCREASE_TOTAL_MESSAGE_COUNT,
  RESET_TOTAL_MESSAGE_COUNT,
  RESET_SELECTED_MESSAGE_COUNT,
  UPDATE_SELECTED_USER_TIMESTAMP,
} from "../constants/types";

export const initMessageCount = (usersList, loadMore) => (
  dispatch
) => {
  let messageCountList = {};
  usersList &&
    usersList.length > 0 &&
    usersList.forEach((user) => {
      messageCountList[`${user?.userId}_${user?.to_user_id}`] = user.unreadCount || 0;
    });
  let total = 0;
  for (const key in messageCountList) {
    if (key !== "totalCount" && messageCountList[key] > 0) {
      total = total + messageCountList[key];
    }
  }
  dispatch({
    type: INIT_MESSAGE_COUNT,
    payload: messageCountList,
  });
  dispatch({
    type: INIT_TOTAL_MESSAGE_COUNT,
    payload: total,
  });
};

export const updateOneMessageCount = (id) => (dispatch) => {
  dispatch(increaseTotalMessageCount());
  dispatch({
    type: UPDATE_ONE_MESSAGE_COUNT,
    payload: id,
  });
};

export const increaseTotalMessageCount = () => (dispatch) => {
  dispatch({
    type: INCREASE_TOTAL_MESSAGE_COUNT,
    payload: true,
  });
};

export const resetTotalMessageCount = () => (dispatch) => {
  dispatch({
    type: RESET_TOTAL_MESSAGE_COUNT,
    payload: true,
  });
};

export const resetSelectedChatCount = (id) => (dispatch) => {
  dispatch({
    type: RESET_SELECTED_MESSAGE_COUNT,
    payload: id,
  });
};

export const updateSelectUserTimestamp = (id) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_USER_TIMESTAMP,
    payload: id,
  });
};
