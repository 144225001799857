import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dateFormat } from "../../utils/formatDate";
import { fetchChatUserDetails } from '../../actions/update-message-list.action'
import { toggleUnreadList } from "../../actions/update-users-list.action";
import { logout } from "../../actions/auth.action";
import UserInfo from "../userInfo/UserInfo";
import SeedUser from "../seedUser/SeedUser";

export default function MessageHeader() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const selectedChatUserDetails = messages?.selectedChatUserDetails;
  const userDetails =
    selectedChatUserDetails &&
    Array.isArray(selectedChatUserDetails) &&
    selectedChatUserDetails[0];
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const onCloseUserInfo = () => setOpenUserInfo(false);
  const fetchUserDetails = () => {
    dispatch(fetchChatUserDetails(selectedChatUserDetails[0]?.userId))
    setOpenUserInfo(true);
  }
  return (
    <div className="message__header">
      {userDetails && (
        <Avatar
          alt={userDetails?.userName}
          src={userDetails?.imageLink}
          onClick={fetchUserDetails}
        />
      )}
      <div className="message__headerInfo">
        {userDetails && (
          <>
            <h3>{`${userDetails?.userName}  (
              ${userDetails?.userId} - 
              ${userDetails?.metadata?.city}
              )`}</h3>
            <p>
              {`Last seen ${dateFormat(
                userDetails?.lastSeenAtTime
              )}`}
            </p>
          </>
        )}
      </div>
      {userDetails && (<div className="message__headerRight">
        <SeedUser />
      </div>)}
      <UserInfo isOpen={openUserInfo} handleClose={onCloseUserInfo} />
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    background: "#e0e0e0",
    borderRadius: 3,
    border: 0,
    color: "black",
    height: 22,
    padding: "15px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
  },
  label: {
    textTransform: "capitalize",
    fontSize: "smaller",
  },
  count: {
    background: "#2bb793",
    borderRadius: 4,
    border: 0,
    color: "white",
    height: 22,
    padding: "15px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
  },
  countLabel: {
    fontWeight: 700,
    fontSize: "smaller",
  },
});
