import { Get } from "../utils/api";
import APIConfig from "../utils/APIConfig";
import { FETCHED_AGENT_SEEDUSER_MAPPING } from "../constants/types";
import { updateUsersList } from "./update-users-list.action";
import { initMessageCount } from "./update-new-message-count.action";

export const fetchAgentSeedMapping = () => (dispatch) => {
  Get(APIConfig.AGENT_SEEDUSER_MAPPING)
    .then((res) => {
      let seedUsers = {};
      if (res && res.length > 0) {
        res.forEach((seed) => {
          seedUsers[seed?.id] = seed;
        });
      }
      dispatch({
        type: FETCHED_AGENT_SEEDUSER_MAPPING,
        payload: seedUsers,
      });
    })
    .catch((error) => console.error(error));
};

export const fetchUsersList = () => (dispatch) => {
  Get(APIConfig.AGENT_SEEDUSER_CHAT_LIST)
    .then((response) => {
      dispatch(updateUsersList(response?.users));
      dispatch(initMessageCount(response?.users));
    })
    .catch((error) => console.error(error));
};
