import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useFirebaseFileLoader from "../../utils/FirebaseFileLoader";
import Avatar from "@material-ui/core/Avatar";
import { fetchChatUserDetails } from '../../actions/update-message-list.action';
import UserInfo from "../userInfo/UserInfo";
import "./SeedUser.css";

export default function SeedUser() {
  const dispatch = useDispatch();
  const seedUser = useSelector((state) => state.messages?.selectedSeedUser);
  const id = seedUser?.id;
  const name = seedUser?.full_name;
  const url = seedUser?.photo_firebase_path;
  const [profileUrl, loading] = useFirebaseFileLoader(url);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const onCloseUserInfo = () => setOpenUserInfo(false);
  const fetchUserDetails = () => {
    dispatch(fetchChatUserDetails(id))
    setOpenUserInfo(true);
  }
  return (
    <>
      <div className="seed__user">
        <Avatar src={profileUrl} onClick={fetchUserDetails} />
        <div className="seed__userInfo">
          <span>{name}</span>
          <span>{id}</span>
        </div>
      </div>
      <UserInfo isOpen={openUserInfo} handleClose={onCloseUserInfo} />
    </>

  );
}
