import React from "react";

export const StarterBadge = (props) => (
  <svg width={104} height={32} viewBox="0 0 104 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        stroke="#A5A5A5"
        strokeOpacity={0.5}
        d="M92.308 4.5H24.692c-2.533 0-3.459.246-4.387.742a4.962 4.962 0 00-2.063 2.063c-.496.928-.742 1.855-.742 4.387v8.616c0 2.533.246 3.459.742 4.387a4.962 4.962 0 002.063 2.063c.928.496 1.854.742 4.387.742h67.616c2.533 0 3.459-.246 4.387-.742a4.962 4.962 0 002.063-2.063c.496-.928.742-1.854.742-4.387v-8.616c0-2.533-.246-3.459-.742-4.387a4.962 4.962 0 00-2.063-2.063c-.928-.496-1.855-.742-4.387-.742z"
      />
      <path
        fill="#6A6A6A"
        fillRule="nonzero"
        d="M44.264 20.204c.68 0 1.268-.11 1.764-.33.496-.22.876-.528 1.14-.924a2.42 2.42 0 00.396-1.374c0-.728-.2-1.29-.6-1.686-.4-.396-1.024-.73-1.872-1.002a9.387 9.387 0 01-1.008-.372c-.224-.104-.38-.214-.468-.33a.724.724 0 01-.132-.45c0-.264.1-.466.3-.606.2-.14.472-.21.816-.21.648 0 1.256.232 1.824.696l.912-1.068a3.73 3.73 0 00-1.254-.804c-.46-.176-1.002-.264-1.626-.264-.576 0-1.092.098-1.548.294-.456.196-.812.476-1.068.84s-.384.786-.384 1.266c0 .624.194 1.14.582 1.548.388.408 1.042.752 1.962 1.032a6.1 6.1 0 01.936.36c.216.112.368.238.456.378.088.14.132.314.132.522 0 .32-.118.57-.354.75-.236.18-.558.27-.966.27-.4 0-.77-.068-1.11-.204-.34-.136-.67-.34-.99-.612l-.996 1.092c.376.36.826.648 1.35.864.524.216 1.126.324 1.806.324zM52.336 20v-6.804h2.052l.192-1.512h-6.36v1.512h2.148V20h1.968zm4.04 0l.42-1.788h2.424L59.652 20h2.088l-2.508-8.316h-2.388L54.336 20h2.04zm2.508-3.216h-1.752l.876-3.672.876 3.672zM64.82 20v-3.18h.768L67.292 20h2.22L67.4 16.46c.552-.248.956-.55 1.212-.906s.384-.802.384-1.338c0-.856-.286-1.492-.858-1.908-.572-.416-1.426-.624-2.562-.624h-2.724V20h1.968zm.828-4.536h-.828v-2.4h.708c.488 0 .85.092 1.086.276.236.184.354.476.354.876 0 .432-.11.748-.33.948-.22.2-.55.3-.99.3zM73.96 20v-6.804h2.052l.192-1.512h-6.36v1.512h2.148V20h1.968zm8.636 0v-1.416h-3.132V16.46h2.556v-1.38h-2.556v-1.992h2.928l.204-1.404h-5.1V20h5.1zm3.68 0v-3.18h.768L88.748 20h2.22l-2.112-3.54c.552-.248.956-.55 1.212-.906s.384-.802.384-1.338c0-.856-.286-1.492-.858-1.908-.572-.416-1.426-.624-2.562-.624h-2.724V20h1.968zm.828-4.536h-.828v-2.4h.708c.488 0 .85.092 1.086.276.236.184.354.476.354.876 0 .432-.11.748-.33.948-.22.2-.55.3-.99.3z"
      />
      <g
        fill="#FFF"
        stroke="#A5A5A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M17.895 9l.157 3.93.645-.342L20 11.287 30.402 21.69l-3.645 3.645a8.276 8.276 0 01-5.852 2.424h-.04l-1.626 1.625a1.38 1.38 0 01-1.951 0l-6.718-6.717a1.38 1.38 0 010-1.952l1.627-1.626a3.237 3.237 0 01.77-3.3 29.082 29.082 0 001.667-7.7 1.296 1.296 0 011.643-1.142A2.225 2.225 0 0117.896 9z" />
        <path d="M19.131 12.154l6.502-6.502a1.839 1.839 0 112.6 2.602l-6.501 6.501" />
        <path d="M21.731 14.754l7.37-7.368a1.84 1.84 0 012.6 2.601l-7.368 7.369m0 0l5.635-5.635a1.839 1.839 0 112.6 2.601l-5.634 5.635m0 0l3.033-3.034a1.84 1.84 0 112.6 2.6l-3.9 3.902" />
      </g>
      <g
        fill="#FFF"
        stroke="#6A6A6A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M8.29 9.155l1.49 3.639.49-.542.778-1.667 13.335 6.218-2.179 4.672a8.276 8.276 0 01-4.67 4.279l-.037.013-.972 2.084a1.38 1.38 0 01-1.834.668l-8.61-4.015a1.38 1.38 0 01-.667-1.834l.971-2.084a3.239 3.239 0 01-.404-3.365 29.082 29.082 0 00-1.067-7.806A1.296 1.296 0 016.068 7.78 2.225 2.225 0 018.29 9.155z" />
        <path d="M10.53 11.697l3.886-8.335a1.84 1.84 0 113.334 1.556l-3.886 8.334" />
        <path d="M13.864 13.252l4.404-9.446a1.839 1.839 0 113.333 1.555l-4.403 9.444m0 0l3.367-7.222a1.84 1.84 0 113.333 1.555l-3.367 7.222m0 0l1.813-3.89a1.84 1.84 0 113.334 1.556l-2.331 5" />
      </g>
    </g>
  </svg>
);

export const SilverBadge = (props) => (
  <svg width={111} height={32} viewBox="0 0 111 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#6A6A6A"
        d="M24.692 4h55.616c2.675 0 3.645.278 4.623.801a5.456 5.456 0 012.268 2.268c.523.978.801 1.948.801 4.623v8.616c0 2.675-.278 3.645-.801 4.623a5.456 5.456 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H24.692c-2.675 0-3.645-.278-4.623-.801a5.456 5.456 0 01-2.268-2.268c-.523-.978-.801-1.948-.801-4.623v-8.616c0-2.675.278-3.645.801-4.623a5.456 5.456 0 012.268-2.268C21.047 4.278 22.017 4 24.692 4z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M44.264 20.204c.68 0 1.268-.11 1.764-.33.496-.22.876-.528 1.14-.924a2.42 2.42 0 00.396-1.374c0-.728-.2-1.29-.6-1.686-.4-.396-1.024-.73-1.872-1.002a9.387 9.387 0 01-1.008-.372c-.224-.104-.38-.214-.468-.33a.724.724 0 01-.132-.45c0-.264.1-.466.3-.606.2-.14.472-.21.816-.21.648 0 1.256.232 1.824.696l.912-1.068a3.73 3.73 0 00-1.254-.804c-.46-.176-1.002-.264-1.626-.264-.576 0-1.092.098-1.548.294-.456.196-.812.476-1.068.84s-.384.786-.384 1.266c0 .624.194 1.14.582 1.548.388.408 1.042.752 1.962 1.032a6.1 6.1 0 01.936.36c.216.112.368.238.456.378.088.14.132.314.132.522 0 .32-.118.57-.354.75-.236.18-.558.27-.966.27-.4 0-.77-.068-1.11-.204-.34-.136-.67-.34-.99-.612l-.996 1.092c.376.36.826.648 1.35.864.524.216 1.126.324 1.806.324zM51.112 20v-8.316h-1.968V20h1.968zm7.052 0l.204-1.56h-3.24v-6.756H53.16V20h5.004zm4.46 0l2.316-8.316h-2.016l-1.488 6.78-1.584-6.78h-2.076l2.4 8.316h2.448zm8.528 0v-1.416H68.02V16.46h2.556v-1.38H68.02v-1.992h2.928l.204-1.404h-5.1V20h5.1zm3.68 0v-3.18h.768L77.304 20h2.22l-2.112-3.54c.552-.248.956-.55 1.212-.906s.384-.802.384-1.338c0-.856-.286-1.492-.858-1.908-.572-.416-1.426-.624-2.562-.624h-2.724V20h1.968zm.828-4.536h-.828v-2.4h.708c.488 0 .85.092 1.086.276.236.184.354.476.354.876 0 .432-.11.748-.33.948-.22.2-.55.3-.99.3z"
      />
      <g
        fill="#FFF"
        stroke="#A5A5A5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M17.895 9l.157 3.93.645-.342L20 11.287 30.402 21.69l-3.645 3.645a8.276 8.276 0 01-5.852 2.424h-.04l-1.626 1.625a1.38 1.38 0 01-1.951 0l-6.718-6.717a1.38 1.38 0 010-1.952l1.627-1.626a3.237 3.237 0 01.77-3.3 29.082 29.082 0 001.667-7.7 1.296 1.296 0 011.643-1.142A2.225 2.225 0 0117.896 9z" />
        <path d="M19.131 12.154l6.502-6.502a1.839 1.839 0 112.6 2.602l-6.501 6.501" />
        <path d="M21.731 14.754l7.37-7.368a1.84 1.84 0 012.6 2.601l-7.368 7.369m0 0l5.635-5.635a1.839 1.839 0 112.6 2.601l-5.634 5.635m0 0l3.033-3.034a1.84 1.84 0 112.6 2.6l-3.9 3.902" />
      </g>
      <g
        fill="#FFF"
        stroke="#6A6A6A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M8.29 9.155l1.49 3.639.49-.542.778-1.667 13.335 6.218-2.179 4.672a8.276 8.276 0 01-4.67 4.279l-.037.013-.972 2.084a1.38 1.38 0 01-1.834.668l-8.61-4.015a1.38 1.38 0 01-.667-1.834l.971-2.084a3.239 3.239 0 01-.404-3.365 29.082 29.082 0 00-1.067-7.806A1.296 1.296 0 016.068 7.78 2.225 2.225 0 018.29 9.155z" />
        <path d="M10.53 11.697l3.886-8.335a1.84 1.84 0 113.334 1.556l-3.886 8.334" />
        <path d="M13.864 13.252l4.404-9.446a1.839 1.839 0 113.333 1.555l-4.403 9.444m0 0l3.367-7.222a1.84 1.84 0 113.333 1.555l-3.367 7.222m0 0l1.813-3.89a1.84 1.84 0 113.334 1.556l-2.331 5" />
      </g>
      <path
        fill="#6A6A6A"
        d="M95.131 11.131L98 10l-2.869-1.131L94 6l-1.131 2.869L90 10l2.869 1.131L94 14zm0 12L98 22l-2.869-1.131L94 18l-1.131 2.869L90 22l2.869 1.131L94 26zm9.566-5.434L109 16l-4.303-1.697L103 10l-1.697 4.303L97 16l4.303 1.697L103 22z"
      />
    </g>
  </svg>
);

export const GoldBadge = (props) => (
  <svg width={104} height={32} viewBox="0 0 104 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FC9F5B"
        d="M24.692 4h48.616c2.675 0 3.645.278 4.623.801a5.456 5.456 0 012.268 2.268c.523.978.801 1.948.801 4.623v8.616c0 2.675-.278 3.645-.801 4.623a5.456 5.456 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H24.692c-2.675 0-3.645-.278-4.623-.801a5.456 5.456 0 01-2.268-2.268c-.523-.978-.801-1.948-.801-4.623v-8.616c0-2.675.278-3.645.801-4.623a5.456 5.456 0 012.268-2.268C21.047 4.278 22.017 4 24.692 4z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M45.212 20.204c.52 0 1.04-.076 1.56-.228.52-.152.996-.368 1.428-.648v-4.116h-3.18l.192 1.392h1.08v1.884a2.31 2.31 0 01-1.128.276c-.592 0-1.028-.222-1.308-.666-.28-.444-.42-1.198-.42-2.262 0-.984.184-1.718.552-2.202.368-.484.86-.726 1.476-.726.328 0 .618.05.87.15.252.1.526.262.822.486l.996-1.044c-.776-.68-1.7-1.02-2.772-1.02-.752 0-1.43.17-2.034.51-.604.34-1.08.838-1.428 1.494-.348.656-.522 1.44-.522 2.352 0 1.432.32 2.518.96 3.258.64.74 1.592 1.11 2.856 1.11zm8.168 0c.776 0 1.446-.174 2.01-.522.564-.348.996-.85 1.296-1.506.3-.656.45-1.436.45-2.34 0-1.384-.33-2.456-.99-3.216-.66-.76-1.582-1.14-2.766-1.14-.776 0-1.444.174-2.004.522-.56.348-.99.85-1.29 1.506-.3.656-.45 1.432-.45 2.328 0 .92.15 1.706.45 2.358.3.652.73 1.15 1.29 1.494.56.344 1.228.516 2.004.516zm0-1.44c-.56 0-.98-.232-1.26-.696-.28-.464-.42-1.208-.42-2.232 0-1.024.138-1.766.414-2.226.276-.46.698-.69 1.266-.69.568 0 .992.228 1.272.684.28.456.42 1.2.42 2.232 0 1.032-.138 1.778-.414 2.238-.276.46-.702.69-1.278.69zM63.816 20l.204-1.56h-3.24v-6.756h-1.968V20h5.004zm4.148 0c1.224 0 2.22-.314 2.988-.942.768-.628 1.152-1.714 1.152-3.258 0-1.568-.394-2.646-1.182-3.234-.788-.588-1.834-.882-3.138-.882h-2.316V20h2.496zm.12-1.428h-.648v-5.46h.624c.424 0 .78.074 1.068.222.288.148.516.418.684.81.168.392.252.944.252 1.656 0 1.04-.168 1.762-.504 2.166-.336.404-.828.606-1.476.606z"
      />
      <g
        fill="#FFF"
        stroke="#FFC295"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M17.895 9l.157 3.93.645-.342L20 11.287 30.402 21.69l-3.645 3.645a8.276 8.276 0 01-5.852 2.424h-.04l-1.626 1.625a1.38 1.38 0 01-1.951 0l-6.718-6.717a1.38 1.38 0 010-1.952l1.627-1.626a3.237 3.237 0 01.77-3.3 29.082 29.082 0 001.667-7.7 1.296 1.296 0 011.643-1.142A2.225 2.225 0 0117.896 9z" />
        <path d="M19.131 12.154l6.502-6.502a1.839 1.839 0 112.6 2.602l-6.501 6.501" />
        <path d="M21.731 14.754l7.37-7.368a1.84 1.84 0 012.6 2.601l-7.368 7.369m0 0l5.635-5.635a1.839 1.839 0 112.6 2.601l-5.634 5.635m0 0l3.033-3.034a1.84 1.84 0 112.6 2.6l-3.9 3.902" />
      </g>
      <g
        fill="#FFF"
        stroke="#FC9F5B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M8.29 9.155l1.49 3.639.49-.542.778-1.667 13.335 6.218-2.179 4.672a8.276 8.276 0 01-4.67 4.279l-.037.013-.972 2.084a1.38 1.38 0 01-1.834.668l-8.61-4.015a1.38 1.38 0 01-.667-1.834l.971-2.084a3.239 3.239 0 01-.404-3.365 29.082 29.082 0 00-1.067-7.806A1.296 1.296 0 016.068 7.78 2.225 2.225 0 018.29 9.155z" />
        <path d="M10.53 11.697l3.886-8.335a1.84 1.84 0 113.334 1.556l-3.886 8.334" />
        <path d="M13.864 13.252l4.404-9.446a1.839 1.839 0 113.333 1.555l-4.403 9.444m0 0l3.367-7.222a1.84 1.84 0 113.333 1.555l-3.367 7.222m0 0l1.813-3.89a1.84 1.84 0 113.334 1.556l-2.331 5" />
      </g>
      <path
        fill="#FC9F5B"
        d="M88.131 11.131L91 10l-2.869-1.131L87 6l-1.131 2.869L83 10l2.869 1.131L87 14zm0 12L91 22l-2.869-1.131L87 18l-1.131 2.869L83 22l2.869 1.131L87 26zm9.566-5.434L102 16l-4.303-1.697L96 10l-1.697 4.303L90 16l4.303 1.697L96 22z"
      />
    </g>
  </svg>
);

export const DiamondBadge = (props) => (
  <svg width={128} height={32} viewBox="0 0 128 32" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#2F68E3"
        d="M24.692 4h72.616c2.675 0 3.645.278 4.623.801a5.456 5.456 0 012.268 2.268c.523.978.801 1.948.801 4.623v8.616c0 2.675-.278 3.645-.801 4.623a5.456 5.456 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H24.692c-2.675 0-3.645-.278-4.623-.801a5.456 5.456 0 01-2.268-2.268c-.523-.978-.801-1.948-.801-4.623v-8.616c0-2.675.278-3.645.801-4.623a5.456 5.456 0 012.268-2.268C21.047 4.278 22.017 4 24.692 4z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M44.276 20c1.224 0 2.22-.314 2.988-.942.768-.628 1.152-1.714 1.152-3.258 0-1.568-.394-2.646-1.182-3.234-.788-.588-1.834-.882-3.138-.882H41.78V20h2.496zm.12-1.428h-.648v-5.46h.624c.424 0 .78.074 1.068.222.288.148.516.418.684.81.168.392.252.944.252 1.656 0 1.04-.168 1.762-.504 2.166-.336.404-.828.606-1.476.606zM52.06 20v-8.316h-1.968V20h1.968zm3.152 0l.42-1.788h2.424L58.488 20h2.088l-2.508-8.316H55.68L53.172 20h2.04zm2.508-3.216h-1.752l.876-3.672.876 3.672zM63.224 20l.144-3.228a28.68 28.68 0 00-.036-3.42l1.368 5.772h1.86l1.284-5.772c-.024.416-.036.856-.036 1.32 0 .608.02 1.3.06 2.076L68.012 20h1.932l-.624-8.316h-2.496l-1.164 5.64-1.248-5.64h-2.484L61.304 20h1.92zm11.636.204c.776 0 1.446-.174 2.01-.522.564-.348.996-.85 1.296-1.506.3-.656.45-1.436.45-2.34 0-1.384-.33-2.456-.99-3.216-.66-.76-1.582-1.14-2.766-1.14-.776 0-1.444.174-2.004.522-.56.348-.99.85-1.29 1.506-.3.656-.45 1.432-.45 2.328 0 .92.15 1.706.45 2.358.3.652.73 1.15 1.29 1.494.56.344 1.228.516 2.004.516zm0-1.44c-.56 0-.98-.232-1.26-.696-.28-.464-.42-1.208-.42-2.232 0-1.024.138-1.766.414-2.226.276-.46.698-.69 1.266-.69.568 0 .992.228 1.272.684.28.456.42 1.2.42 2.232 0 1.032-.138 1.778-.414 2.238-.276.46-.702.69-1.278.69zM82.044 20v-3.048c0-.664-.022-1.238-.066-1.722a48.437 48.437 0 00-.186-1.674l2.4 6.444H86.7v-8.316h-1.764v3.36c0 .968.088 2 .264 3.096l-2.46-6.456h-2.448V20h1.752zm9.2 0c1.224 0 2.22-.314 2.988-.942.768-.628 1.152-1.714 1.152-3.258 0-1.568-.394-2.646-1.182-3.234-.788-.588-1.834-.882-3.138-.882h-2.316V20h2.496zm.12-1.428h-.648v-5.46h.624c.424 0 .78.074 1.068.222.288.148.516.418.684.81.168.392.252.944.252 1.656 0 1.04-.168 1.762-.504 2.166-.336.404-.828.606-1.476.606z"
      />
      <g
        fill="#FFF"
        stroke="#6F9DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M17.895 9l.157 3.93.645-.342L20 11.287 30.402 21.69l-3.645 3.645a8.276 8.276 0 01-5.852 2.424h-.04l-1.626 1.625a1.38 1.38 0 01-1.951 0l-6.718-6.717a1.38 1.38 0 010-1.952l1.627-1.626a3.237 3.237 0 01.77-3.3 29.082 29.082 0 001.667-7.7 1.296 1.296 0 011.643-1.142A2.225 2.225 0 0117.896 9z" />
        <path d="M19.131 12.154l6.502-6.502a1.839 1.839 0 112.6 2.602l-6.501 6.501" />
        <path d="M21.731 14.754l7.37-7.368a1.84 1.84 0 012.6 2.601l-7.368 7.369m0 0l5.635-5.635a1.839 1.839 0 112.6 2.601l-5.634 5.635m0 0l3.033-3.034a1.84 1.84 0 112.6 2.6l-3.9 3.902" />
      </g>
      <g
        fill="#FFF"
        stroke="#2F68E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M8.29 9.155l1.49 3.639.49-.542.778-1.667 13.335 6.218-2.179 4.672a8.276 8.276 0 01-4.67 4.279l-.037.013-.972 2.084a1.38 1.38 0 01-1.834.668l-8.61-4.015a1.38 1.38 0 01-.667-1.834l.971-2.084a3.239 3.239 0 01-.404-3.365 29.082 29.082 0 00-1.067-7.806A1.296 1.296 0 016.068 7.78 2.225 2.225 0 018.29 9.155z" />
        <path d="M10.53 11.697l3.886-8.335a1.84 1.84 0 113.334 1.556l-3.886 8.334" />
        <path d="M13.864 13.252l4.404-9.446a1.839 1.839 0 113.333 1.555l-4.403 9.444m0 0l3.367-7.222a1.84 1.84 0 113.333 1.555l-3.367 7.222m0 0l1.813-3.89a1.84 1.84 0 113.334 1.556l-2.331 5" />
      </g>
      <path
        fill="#2F68E3"
        d="M112.131 11.131L115 10l-2.869-1.131L111 6l-1.131 2.869L107 10l2.869 1.131L111 14zm0 12L115 22l-2.869-1.131L111 18l-1.131 2.869L107 22l2.869 1.131L111 26zm9.566-5.434L126 16l-4.303-1.697L120 10l-1.697 4.303L114 16l4.303 1.697L120 22z"
      />
    </g>
  </svg>
);

export const InfluencerBadge = (props) => (
  <svg width={144} height={32} viewBox="0 0 144 32" {...props}>
    <defs>
      <linearGradient id="prefix__a" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#FE5196" />
        <stop offset="100%" stopColor="#F77062" />
      </linearGradient>
      <linearGradient id="prefix__b" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#FE5196" />
        <stop offset="100%" stopColor="#F77062" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#prefix__a)"
        d="M7.692 0h87.616c2.675 0 3.645.278 4.623.801a5.456 5.456 0 012.268 2.268c.523.978.801 1.948.801 4.623v8.616c0 2.675-.278 3.645-.801 4.623a5.456 5.456 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H7.692c-2.675 0-3.645-.278-4.623-.801a5.456 5.456 0 01-2.268-2.268C.278 19.953 0 18.983 0 16.308V7.692c0-2.675.278-3.645.801-4.623A5.456 5.456 0 013.069.801C4.047.278 5.017 0 7.692 0z"
        transform="translate(17 4)"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M43.748 20v-8.316H41.78V20h1.968zm3.8 0v-3.048c0-.664-.022-1.238-.066-1.722a48.437 48.437 0 00-.186-1.674l2.4 6.444h2.508v-8.316H50.44v3.36c0 .968.088 2 .264 3.096l-2.46-6.456h-2.448V20h1.752zm8.672 0v-3.3h2.424v-1.404H56.22v-2.208h2.784l.204-1.404h-4.956V20h1.968zm9.38 0l.204-1.56h-3.24v-6.756h-1.968V20H65.6zm4.472.204c.704 0 1.3-.128 1.788-.384s.854-.608 1.098-1.056c.244-.448.366-.956.366-1.524v-5.556h-1.968v5.472c0 .536-.102.934-.306 1.194-.204.26-.53.39-.978.39-.44 0-.762-.13-.966-.39-.204-.26-.306-.658-.306-1.194v-5.472h-1.968v5.556c0 .904.276 1.624.828 2.16.552.536 1.356.804 2.412.804zM80.364 20v-1.416h-3.132V16.46h2.556v-1.38h-2.556v-1.992h2.928l.204-1.404h-5.1V20h5.1zm3.464 0v-3.048c0-.664-.022-1.238-.066-1.722a48.437 48.437 0 00-.186-1.674l2.4 6.444h2.508v-8.316H86.72v3.36c0 .968.088 2 .264 3.096l-2.46-6.456h-2.448V20h1.752zm10.124.204c.536 0 1.026-.1 1.47-.3.444-.2.822-.456 1.134-.768l-.876-1.128a4.652 4.652 0 01-.804.498 1.93 1.93 0 01-.828.174c-.576 0-1.026-.234-1.35-.702-.324-.468-.486-1.186-.486-2.154 0-.984.162-1.712.486-2.184.324-.472.766-.708 1.326-.708.272 0 .528.048.768.144.24.096.484.24.732.432l.936-1.116a3.906 3.906 0 00-1.146-.684 3.919 3.919 0 00-1.386-.228c-.728 0-1.378.17-1.95.51-.572.34-1.02.838-1.344 1.494-.324.656-.486 1.436-.486 2.34 0 .928.158 1.722.474 2.382.316.66.758 1.158 1.326 1.494.568.336 1.236.504 2.004.504zm9.08-.204v-1.416H99.9V16.46h2.556v-1.38H99.9v-1.992h2.928l.204-1.404h-5.1V20h5.1zm3.68 0v-3.18h.768l1.704 3.18h2.22l-2.112-3.54c.552-.248.956-.55 1.212-.906s.384-.802.384-1.338c0-.856-.286-1.492-.858-1.908-.572-.416-1.426-.624-2.562-.624h-2.724V20h1.968zm.828-4.536h-.828v-2.4h.708c.488 0 .85.092 1.086.276.236.184.354.476.354.876 0 .432-.11.748-.33.948-.22.2-.55.3-.99.3z"
      />
      <g
        fill="#FFF"
        stroke="#FB9A95"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M17.895 9l.157 3.93.645-.342L20 11.287 30.402 21.69l-3.645 3.645a8.276 8.276 0 01-5.852 2.424h-.04l-1.626 1.625a1.38 1.38 0 01-1.951 0l-6.718-6.717a1.38 1.38 0 010-1.952l1.627-1.626a3.237 3.237 0 01.77-3.3 29.082 29.082 0 001.667-7.7 1.296 1.296 0 011.643-1.142A2.225 2.225 0 0117.896 9z" />
        <path d="M19.131 12.154l6.502-6.502a1.839 1.839 0 112.6 2.602l-6.501 6.501" />
        <path d="M21.731 14.754l7.37-7.368a1.84 1.84 0 012.6 2.601l-7.368 7.369m0 0l5.635-5.635a1.839 1.839 0 112.6 2.601l-5.634 5.635m0 0l3.033-3.034a1.84 1.84 0 112.6 2.6l-3.9 3.902" />
      </g>
      <g
        fill="#FFF"
        stroke="#FD578E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M8.29 9.155l1.49 3.639.49-.542.778-1.667 13.335 6.218-2.179 4.672a8.276 8.276 0 01-4.67 4.279l-.037.013-.972 2.084a1.38 1.38 0 01-1.834.668l-8.61-4.015a1.38 1.38 0 01-.667-1.834l.971-2.084a3.239 3.239 0 01-.404-3.365 29.082 29.082 0 00-1.067-7.806A1.296 1.296 0 016.068 7.78 2.225 2.225 0 018.29 9.155z" />
        <path d="M10.53 11.697l3.886-8.335a1.84 1.84 0 113.334 1.556l-3.886 8.334" />
        <path d="M13.864 13.252l4.404-9.446a1.839 1.839 0 113.333 1.555l-4.403 9.444m0 0l3.367-7.222a1.84 1.84 0 113.333 1.555l-3.367 7.222m0 0l1.813-3.89a1.84 1.84 0 113.334 1.556l-2.331 5" />
      </g>
      <path
        fill="url(#prefix__b)"
        d="M129.131 14.131L132 13l-2.869-1.131L128 9l-1.131 2.869L124 13l2.869 1.131L128 17zm0 12L132 25l-2.869-1.131L128 21l-1.131 2.869L124 25l2.869 1.131L128 29zm9.566-5.434L143 19l-4.303-1.697L137 13l-1.697 4.303L131 19l4.303 1.697L137 25z"
        transform="translate(-2 -3)"
      />
    </g>
  </svg>
);
