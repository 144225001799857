import React, { Component } from "react";
import { connect } from "react-redux";
import { dateFormat } from "../../utils/formatDate";
import { Avatar } from "@material-ui/core";
import {
  APPLOZIC_BASE_URL,
  APPLOZIC_KEY,
  APPLOZIC_PASSWORD,
  APPLOZIC_WEBSOCKET_URL,
} from "../../utils/config";
import {
  setSelectedChat,
  setSelectSeedUser,
} from "../../actions/update-message-list.action";
import { resetSelectedChatCount, updateOneMessageCount } from "../../actions/update-new-message-count.action";
import { initUserLoading, updateMessageList } from "../../actions/update-message-list.action";

import UserMessageCount from "./UserMessageCount";
import Mixpanel from "../../utils/Mixpanel";


class UserChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oneToOneMessage: true,
    };
  }
  fetchChatForSelectedUser = async () => {
    const that = this;
    const chat = this.props.chat;
    that.props.setSelectSeedUser(chat?.to_user_id);
    that.props.resetSelectedChatCount(`${chat?.userId}_${chat?.to_user_id}`);
    that.props.setSelectedChat(chat?.userId);
  };
  onMessageRecieved = (response) => {
    const selectedChatUser =
      this.props.messages?.selectedChatUserDetails;
    if (
      !!selectedChatUser &&
      (response?.message?.from ===
        (selectedChatUser && selectedChatUser[0] && selectedChatUser[0].userId))
    ) {
      this.props.updateOneMessageCount();
    }
  };
  onMessageSent = () => {
    const selectedChatUser = this.props.messages?.selectedChatUserDetails;
    const id = selectedChatUser && selectedChatUser[0].userId;
    const that = this;
    window.Applozic.ALApiService.conversationReadUpdate({
      data: `userId=${encodeURIComponent(id)}`,
      success: function (result) {
        window.Applozic.ALApiService.getMessages({
          data: {
            startIndex: 0,
            userId: id,
            pageSize: 50,
          },
          success: function (response) {
            that.props.updateMessageList(response);
          },
          error: function () {
            console.log("send failed");
          },
        });
      },
      error: function () { },
    });
  };
  userOnAppLozic = () => {
    let fromId = this.props.messages?.selectedSeedUser?.id;
    let password = true;
    const that = this;
    that.props.initUserLoading();
    let events = {
      onConnectFailed: function (resp) {
        window.Applozic.ALSocket.reconnect();
      },
      onConnect: function (resp) {
        setTimeout(that.fetchChatForSelectedUser, 0);
      },
      onMessageDelivered: function (resp) { },
      onMessageRead: function (resp) {
        //called when a message is read by the receiver
      },
      onMessageDeleted: function (resp) { },
      onConversationDeleted: function (resp) { },
      onUserConnect: function (resp) { },
      onUserDisconnect: function (resp) { },
      onConversationReadFromOtherSource: function (resp) { },
      onConversationRead: function (resp) {
        //called when the conversation thread is read by the receiver
      },
      onMessageReceived: function (resp) {
        // that.newMessageCount(resp);
        that.onMessageRecieved(resp);
        // that.props.typingStatusOfOtherUser();
      },
      onMessageSentUpdate: function (resp) { },
      onMessageSent: function (resp) { },
      onUserBlocked: function (resp) { },
      onUserUnblocked: function (resp) { },
      onUserActivated: function (resp) { },
      onUserDeactivated: function (resp) { },
      connectToSocket: function (resp) { },
      onMessage: function (resp) {
        //called when the websocket receive the data
      },
      onTypingStatus: function (resp) {
        that.props.typingStatusOfOtherUser(resp);
      },
    };
    window.Applozic.ALApiService.login({
      data: {
        baseUrl: APPLOZIC_BASE_URL,
        alUser: {
          userId: fromId, //Logged in user's id, a unique identifier for user
          password: password ? APPLOZIC_PASSWORD : null, //Enter password here for the userId passed above, read this if you want to add additional security by verifying password from your server https://www.applozic.com/docs/configuration.html#access-token-url
          imageLink: "", //User's profile picture url
          email: "", //optional
          contactNumber: "", //optional, pass with internationl code eg: +13109097458
          appVersionCode: 108,
          applicationId: `${process.env.REACT_APP_APPLOZIC_KEY}`, //Get your App ID from [Applozic Dashboard](https://console.applozic.com/settings/install)
        },
      },
      success: function (response) {
        Mixpanel.identify(response.userId);
        let data = {};
        data.token = response.token;
        data.deviceKey = response.deviceKey;
        data.websocketUrl = APPLOZIC_WEBSOCKET_URL;
        //Get your App ID from [Applozic Dashboard](https://console.applozic.com/settings/install)
        window.Applozic.ALSocket.init(APPLOZIC_KEY, data, events);
        // This method initializes socket connection
      },
      error: function () {
        that.props.initUserLoading(false);
      },
    });
  };

  render() {
    const messages = this.props.messages;
    const user = this.props.chat;
    const seedUser = messages?.selectedSeedUser?.id?.toString();
    const selectedId =
      messages?.selectedChatUserDetails &&
      Array.isArray(messages?.selectedChatUserDetails) &&
      messages?.selectedChatUserDetails.length > 0 &&
      messages?.selectedChatUserDetails[0]?.userId;
    const isSelected = `${selectedId}_${seedUser}` === `${user?.userId}_${user?.to_user_id}`;
    return (
      <div
        className={`sidebarChat ${isSelected && 'sidebarChat__select'}`}
        onClick={() => this.userOnAppLozic()}
      >
        <Avatar alt={user?.userName} src={user?.imageLink} />
        <div className="sidebarChat__info">
          <h2>{`${user?.userName} - ${user?.userId}`}</h2>
          <p>{dateFormat(user?.lastMessageAtTime)}</p>
        </div>
        <div className="sidebarChat__infoRight">
          <UserMessageCount id={`${user?.userId}_${user?.to_user_id}`} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  initialized: state.init.initialized,
  activeUser: state.activeUser,
  auth: state.auth,
  usersList: state.users,
  messages: state.messages,
});

export default connect(mapStateToProps, {
  initUserLoading,
  setSelectedChat,
  setSelectSeedUser,
  resetSelectedChatCount,
  updateMessageList,
  updateOneMessageCount
})(UserChat);
