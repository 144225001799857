import React from "react";

import {
  StarterBadge,
  SilverBadge,
  GoldBadge,
  DiamondBadge,
  InfluencerBadge,
} from "../assets/svg";

// Init App
export const INITIALIZE_APP = "INITIALIZE_APP";
// auth
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const CHANGE_USER_PROFILE_IMAGE = "CHANGE_USER_PROFILE_IMAGE";

// Users
export const UPDATE_USERS_LIST = "UPDATE_USERS_LIST";
export const LOAD_MORE_USERS_LIST = "LOAD_MORE_USERS_LIST";
export const SET_LAST_CREATED_AT_TIME_USERS = "SET_LAST_CREATED_AT_TIME_USERS";
export const SET_USER_TYPING = "SET_USER_TYPING";
export const TOGGLE_SHOW_UNREAD_LIST = "TOGGLE_SHOW_UNREAD_LIST";

// Messages
export const MESSAGE_LOADING_STARTED = "MESSAGE_LOADING_STARTED";
export const NEW_MESSAGE_SENT = "NEW_MESSAGE_SENT";
export const UPDATE_MESSAGES_LIST = "UPDATE_MESSAGES_LIST";
export const MESSAGES_LIST_HAS_MORE = "MESSAGES_LIST_HAS_MORE";
export const LOAD_MORE_MESSAGE_LIST = "LOAD_MORE_MESSAGE_LIST";
export const UPDATE_LAST_MESSAGE_TIME = "UPDATE_LAST_MESSAGE_TIME";
export const SET_SELECTED_CHAT = "SET_SELECTED_CHAT";
export const UPDATE_SELECTED_CHAT_USER_DETAILS =
  "UPDATE_SELECTED_CHAT_USER_DETAILS";
export const FETCH_SELECTED_CHAT_USER_DETAILS =
  "FETCH_SELECTED_CHAT_USER_DETAILS";
export const UPDATE_JOBS_TITLES_LIST = "UPDATE_JOBS_TITLES_LIST";
export const FETCH_JOBS_LOADING = "FETCH_JOBS_LOADING";
export const FETCH_JOBS_FOR_TITLE = "FETCH_JOBS_FOR_TITLE";
export const UPDATE_FAQ_LIST = "UPDATE_FAQ_LIST";
export const GOOGLE_SHEET_ENTRY = "GOOGLE_SHEET_ENTRY";
export const OPEN_FAQ_PANEL = "OPEN_FAQ_PANEL";
export const CLOSE_FAQ_PANEL = "CLOSE_FAQ_PANEL";
export const LOAD_USER_INFO = 'LOAD_USER_INFO'
//socket server related action types
// Users - Outgoing
export const USER_TYPING = "USER_TYPING";

// Users - Incoming
export const USER_CONNECTED = "USER_CONNECTED";
export const USER_LOADING_STARTED = "USER_LOADING_STARTED";
export const USER_LOADING_MORE_STARTED = "USER_LOADING_MORE_STARTED";
export const USERS_LIST_UPDATED = "USERS_LIST_UPDATED";
export const INIT_MESSAGE_COUNT = "INIT_MESSAGE_COUNT";
export const INIT_TOTAL_MESSAGE_COUNT = "INIT_TOTAL_MESSAGE_COUNT";
export const UPDATE_ONE_MESSAGE_COUNT = "UPDATE_ONE_MESSAGE_COUNT";
export const INCREASE_TOTAL_MESSAGE_COUNT = "INCREASE_TOTAL_MESSAGE_COUNT";
export const RESET_TOTAL_MESSAGE_COUNT = "RESET_TOTAL_MESSAGE_COUNT";
export const RESET_SELECTED_MESSAGE_COUNT = "RESET_SELECTED_MESSAGE_COUNT";
export const UPDATE_SELECTED_USER_TIMESTAMP = "UPDATE_SELECTED_USER_TIMESTAMP";

// Messages - Outgoing
export const NEW_MESSAGE = "NEW_MESSAGE";

// Messages - Incoming
export const NEW_MESSAGE_RECEIVED = "NEW_MESSAGE_RECEIVED";
export const MESSAGES_LIST_UPDATED = "MESSAGES_LIST_UPDATED";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const SET_REPLY_TO_TAG_MESSAGE = "SET_REPLY_TO_TAG_MESSAGE";

// Commands - Outgoing
export const NEW_USER_NAME = "NEW_USER_NAME";
export const REMOVE_LAST_MESSAGE = "REMOVE_LAST_MESSAGE";
export const FADE_LAST_MESSAGE = "FADE_LAST_MESSAGE";

//seed users
export const FETCHED_AGENT_SEEDUSER_MAPPING = "FETCHED_AGENT_SEEDUSER_MAPPING";
export const SET_SEED_USER_INFO = "SET_SEED_USER_INFO";

//badges
export const CLAP_ICONS = {
  starter: <StarterBadge />,
  silver: <SilverBadge />,
  gold: <GoldBadge />,
  diamond: <DiamondBadge />,
  influencer: <InfluencerBadge />,
};

//clap levels
export const CLAP_LEVELS = [
  { level: "influencer", value: 15000 },
  { level: "diamond", value: 10000 },
  { level: "gold", value: 1000 },
  { level: "silver", value: 100 },
  { level: "starter", value: 0 },
];

//clap colors
export const CLAP_COLORS = {
  starter: "#fff",
  silver: "#6A6A6A",
  gold: "#fc9f5b",
  diamond: "#2F68E3",
  influencer: "#FE5196",
};
