import { Get, OpenPost } from "../utils/api";
import { AddDataToLocalStorage } from "../utils/LocalStorage";
import { initMessageCount } from "./update-new-message-count.action";
import {
  USER_LOADING_STARTED,
  USER_LOADING_MORE_STARTED,
  UPDATE_USERS_LIST,
  SET_LAST_CREATED_AT_TIME_USERS,
  LOAD_MORE_USERS_LIST,
  TOGGLE_SHOW_UNREAD_LIST,
} from "../constants/types";
import _ from "lodash";

export const initLoadingUser = () => (dispatch) => {
  dispatch({
    type: USER_LOADING_STARTED,
    payload: true,
  });
};

export const updateUsersList = (usersList) => (dispatch) => {
  dispatch({
    type: UPDATE_USERS_LIST,
    payload: usersList,
  });
};

export const addMoreUsersList = (usersList) => (dispatch) => {
  dispatch({
    type: LOAD_MORE_USERS_LIST,
    payload: usersList,
  });
};

export const setLastCreatedAtTime = (lastCreatedAtTime) => (dispatch) => {
  dispatch({
    type: SET_LAST_CREATED_AT_TIME_USERS,
    payload: lastCreatedAtTime,
  });
};

export const toggleUnreadList = () => (dispatch) => {
  dispatch({
    type: TOGGLE_SHOW_UNREAD_LIST,
    payload: true,
  });
};
