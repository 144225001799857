import { Get } from "../utils/api";
import APIConfig from "../utils/APIConfig";
import { sortBy } from "../utils/formatDate";
import {
  UPDATE_MESSAGES_LIST,
  LOAD_MORE_MESSAGE_LIST,
  USER_TYPING,
  UPDATE_SELECTED_CHAT_USER_DETAILS,
  FETCH_SELECTED_CHAT_USER_DETAILS,
  MESSAGE_LOADING_STARTED,
  DELETE_MESSAGE,
  SET_REPLY_TO_TAG_MESSAGE,
  SET_SEED_USER_INFO,
  LOAD_USER_INFO
} from "../constants/types";

export const setSelectedChat = (id) => (dispatch) => {
  let selectedChatUserId = id;
  dispatch(initUserLoading(true));
  window.Applozic.ALApiService.conversationReadUpdate({
    data: `userId=${encodeURIComponent(selectedChatUserId)}`,
    success: function (result) {
      window.Applozic.ALApiService.getMessages({
        data: {
          startIndex: 0,
          userId: selectedChatUserId,
          pageSize: 50,
        },
        success: function (response) {
          dispatch(updateMessageList(response));
          dispatch(updateSelectChatUserDetails(response));
        },
        error: function () {
          dispatch(initUserLoading(false));
        },
      });
    },
    error: function () {
      dispatch(initUserLoading(false));
    },
  });
};


export const initUserLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: MESSAGE_LOADING_STARTED,
    loadingMessages: isLoading,
  });
}

export const updateMessageList = (reponse, loadMore, scrollTo) => (
  dispatch
) => {
  if (reponse?.data?.message) {
    let messages = reponse?.data?.message.sort(sortBy("createdAtTime"));
    let hasMore = messages?.length === 50 ? true : false;
    if (loadMore) {
      dispatch({
        type: LOAD_MORE_MESSAGE_LIST,
        messages: messages,
        scrollTo: scrollTo,
        hasMore: hasMore,
        lastCreatedTime: messages && messages[0]?.createdAtTime,
      });
    } else {
      dispatch({
        type: UPDATE_MESSAGES_LIST,
        messages: messages,
        hasMore: hasMore,
        lastCreatedTime: messages && messages[0]?.createdAtTime,
      });
    }
  } else {
    console.log("end of list");
  }
};

export const loadMoreMessageList = (userId, lastMessageTime, scrollTo) => (
  dispatch
) => {
  window.Applozic.ALApiService.getMessages({
    data: {
      startIndex: 0,
      userId: userId,
      endTime: lastMessageTime,
      pageSize: 50,
    },
    success: function (response) {
      dispatch(updateMessageList(response, true, scrollTo));
    },
    error: function () {
      console.log("error");
    },
  });
};

export const updateSelectChatUserDetails = (reponse) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_CHAT_USER_DETAILS,
    payload: reponse && reponse.data && reponse.data.userDetails,
  });
};

export const fetchChatUserDetails = (userId) => (dispatch) => {
  dispatch({
    type: LOAD_USER_INFO,
    payload: true
  })
  Get(`${APIConfig.USER_PROFILE}${userId}`)
    .then((res) => {
      dispatch({
        type: FETCH_SELECTED_CHAT_USER_DETAILS,
        payload: res,
      });
    })
    .catch((error) => {
      dispatch({
        type: LOAD_USER_INFO,
        payload: false
      })
      console.error(error);
    });
};

export const deleteMsg = (messageKey) => (dispatch) => {
  window.Applozic.ALApiService.deleteMessage({
    data: { key: messageKey },
    success: function (response) {
      dispatch({
        type: DELETE_MESSAGE,
        payload: messageKey,
      });
    },
    error: function (error) {
      console.log(error);
    },
  });
};

export const setReplyToTagMessage = (message) => (dispatch) => {
  dispatch({
    type: SET_REPLY_TO_TAG_MESSAGE,
    payload: message,
  });
};

export const typingStatusOfOtherUser = (data) => (dispatch) => {
  if (data !== undefined) {
    dispatch({
      type: USER_TYPING,
      payload: false,
    });
  } else {
    dispatch({
      type: USER_TYPING,
      payload: false,
    });
  }
};

export const setSelectSeedUser = (seedUserId) => (dispatch, getState) => {
  const { seedUsers } = getState();
  const seedUserInfo = seedUsers[seedUserId];
  dispatch({
    type: SET_SEED_USER_INFO,
    payload: seedUserInfo,
  });
};
