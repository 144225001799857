import React, { Component } from "react";
import { connect } from "react-redux";
import APIConfig from "../../utils/APIConfig";
import { dateFormat } from "../../utils/formatDate";
import { GetCurrentStorage } from "../../utils/LocalStorage";
import ReactAudioPlayer from "react-audio-player";
import ReactHtmlParser from "react-html-parser";
import Linkify from "react-linkify";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  deleteMsg,
  setReplyToTagMessage,
} from "../../actions/update-message-list.action";
import MessageTagReply from "./MessageTagReply";

class MessageCard extends Component {
  state = {
    anchorEl: null,
    isDeleted: false,
    isReplying: false,
  };
  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleDelete = () => {
    this.setState({ anchorEl: null, isDeleted: true });
    this.props.deleteMsg(this.props.message && this.props.message.key);
  };
  handleReply = () => {
    this.setState({ anchorEl: null });
    this.props.setReplyToTagMessage(this.props.message);
  };
  render() {
    let senderName = GetCurrentStorage("senderName");
    let userName =
      this.props.messages.selectedChatUserDetails &&
      this.props.messages.selectedChatUserDetails[0] &&
      this.props.messages.selectedChatUserDetails[0].userName;
    let chat = this.props.message;
    let blobUrl = chat.fileMeta && chat.fileMeta.blobKey;
    let mediaUrl =
      APIConfig.MCK_FILE_URL + APIConfig.FILE_PREVIEW_URL + blobUrl;
    let contentType = chat.fileMeta && chat.fileMeta.contentType;
    return (
      <div
        className={`chat__message ${chat.type === 5 && "chat__reciever"} ${this
          .state.isDeleted && "chat__deleted"}`}
      >
        <span className="chat__options">
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.handleDelete}>Delete</MenuItem>
            <MenuItem onClick={this.handleReply}>Reply</MenuItem>
          </Menu>
        </span>
        <span className="chat__name">
          {chat.type === 5
            ? chat.metadata && chat.metadata.senderName
              ? `You - ${chat.metadata && chat.metadata.senderName}`
              : "You"
            : userName}
        </span>

        {contentType && contentType.search("image") !== -1 && (
          <div>
            <img
              alt=""
              onClick={() => window.open(mediaUrl, "_blank")}
              src={mediaUrl}
            />
          </div>
        )}
        {contentType && contentType.search("audio") !== -1 && (
          <div>
            <ReactAudioPlayer
              className="iFrame audio"
              src={mediaUrl}
              controls
            />
          </div>
        )}
        {contentType && contentType.search("video") !== -1 && (
          <div>
            <video
              className="iFrame"
              controls
              src={mediaUrl}
              type="video/mp4"
            ></video>
          </div>
        )}
        {contentType && contentType.search("application") !== -1 && (
          <div>
            <a href={mediaUrl}>Download File</a>
          </div>
        )}
        {chat.metadata && chat.metadata.AL_REPLY && (
          <MessageTagReply
            replyMsgKey={chat.metadata.AL_REPLY}
            chat={chat}
            userName={userName}
          />
        )}
        <p>
          <Linkify>{ReactHtmlParser(chat.message)}</Linkify>
          <span className="chat__timestamp">
            {dateFormat(chat.createdAtTime)}
          </span>
        </p>
      </div>
    );
  }
}

export default connect((state) => ({ messages: state.messages }), {
  deleteMsg,
  setReplyToTagMessage,
})(MessageCard);
