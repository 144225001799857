import axios from "axios";
import APIConfig from "./APIConfig";

import { GetCurrentStorage } from "./LocalStorage";

axios.defaults.headers.common["Content-Type"] = "application/json";

// Send an HTTP GET
export const Get = async (url, params) => {
  axios.defaults.headers.common["Authorization"] =
    "Token " + GetCurrentStorage("userToken");
  axios.defaults.headers.common["Content-Type"] = "application/json";

  try {
    const response = await axios.get(url, { params: params });
    return response && response.data ? response.data : null;
  } catch (error) {
    console.error("GET:" + url, error);
    return {
      error: {
        errors: ["Something went wrong!"],
      },
    };
  }
};

// Send an HTTP POST
export const Post = async (url, postBody) => {
  if (GetCurrentStorage("userToken")) {
    axios.defaults.headers.common["Authorization"] =
      "Token " + GetCurrentStorage("userToken");
  }
  try {
    const response = await axios.post(url, postBody);
    console.log("POST:" + url, response);
    return response && response.data ? response.data : null;
  } catch (error) {
    console.error("POST:" + url, error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return {
        error: {
          errors: ["Something went wrong!"],
        },
      };
    }
  }
};

export const OpenPost = async (url, postBody) => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = null;
  try {
    const response = await axios.post(url, postBody);
    return response && response.data ? response.data : null;
  } catch (error) {
    console.error("POST:" + url, error);
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return {
        error: {
          errors: ["Something went wrong!"],
        },
      };
    }
  }
};
export const GoogleGet = async (url, params) => {
  delete axios.defaults.headers.common;
  try {
    const response = await axios.get(url, { params });
    return response ? response : null;
  } catch (error) {
    console.error("Get:" + url, error);
  }
};

export const jobCategory = (id, cityId) => {
  let query = `select j.id,org.name as company,j.title,ma.name as area,
        concat(COALESCE (j.json_data->>'min_salary',NULL),'-',j.max_salary) as salary,  
        j.experience_level_id,j.json_data->>'new_deeplink' as deep_link, el.level as experience_level,
		edul.level as education_level, jc.type
        from core_job j   
        inner join core_jobstitle jt on jt.id=j.type_id   
        inner join core_jobscategory jc on jc.id=jt.job_type_id   
        inner join core_organization org on org.id=j.organization_id   
        inner join core_address ca on j.address_id = ca.id   
		inner join location_area ma on ca.area_id = ma.id  
		inner join core_experienceLevels el on j.experience_level_id = el.id
		inner join core_educationLevels edul on j.education_level_id = edul.id
        where j.status=0 and ma.city_id=${cityId} and jc.id=${id}
        order by jc.type,j.role, j.created_on desc;`;
  return query;
};
