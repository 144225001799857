import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import APIConfig from "../../utils/APIConfig";
import { Post } from "../../utils/api";
import { ApnaTimeLogo } from "../../utils/Svg";
import {
  AddDataToLocalStorage,
  GetCurrentStorage,
} from "../../utils/LocalStorage";
import { loadUser, login } from "../../actions/auth.action";

import "./Login.css";

class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      otp: "",
      error: "",
      otpRecieved: false,
      mobileNumber: "",
      senderName: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.state.mobileNumber) {
      this.setState({ error: "Please Enter Mobile Number" });
    } else {
      if (this.state.mobileNumber.length === 10) {
        Post(APIConfig.GET_OTP, {
          phone_number: `91${this.state.mobileNumber}`,
          retries: 0,
          source: "internal",
        })
          .then((response) => {
            this.setState({ otpRecieved: true, error: "" });
          })
          .catch((error) => console.error(error));
      } else {
        this.setState({ error: "Please Enter 10 Digit Mobile Number" });
      }
    }
  };

  handleLogin = (e) => {
    e.preventDefault();
    this.props.login(
      this.state.senderName,
      this.state.mobileNumber,
      this.state.otp
    );
  };
  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/message" />;
    }
    return (
      <div className="login">
        <div className="login__card">
          <div className="login__header">
            <ApnaTimeLogo />
            <h2>ApnaExperts Login</h2>
          </div>
          <h2>Login / Signup with mobile</h2>
          <p>
            We will send you a One Time Password to authenticate your account
          </p>
          <form
            onSubmit={
              this.state.otpRecieved ? this.handleLogin : this.handleSubmit
            }
          >
            <input
              name="senderName"
              type="text"
              placeholder="Name"
              className="login__input"
              onChange={this.onChange}
            />{" "}
            <br /> <br />
            <input
              name="mobileNumber"
              type="number"
              placeholder="Mobile Number"
              className="login__input"
              onChange={this.onChange}
            />{" "}
            <br />
            {this.state.error && (
              <p className="login__error">{this.state.error}</p>
            )}
            {this.state.otpRecieved && (
              <>
                <br />
                <input
                  name="otp"
                  type="number"
                  placeholder=" Enter OTP "
                  className="login__input"
                  onChange={this.onChange}
                />
              </>
            )}
            <br />
            {this.state.otpRecieved && (
              <button onClick={this.handleSubmit} className="login__resend">
                Resend OTP
              </button>
            )}
            <br />
            <input
              type="submit"
              className="login__button"
              value={this.state.otpRecieved ? "Login" : "Get OTP"}
            />
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth && state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { loadUser, login })(Login);
