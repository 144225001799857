class StorageHelper {
  localStorageSupported;

  constructor() {
    this.localStorageSupported =
      typeof window["localStorage"] != "undefined" &&
      window["localStorage"] != null;
  }

  // add value to storage
  add(key, item) {
    if (this.localStorageSupported) {
      localStorage.setItem(key, item);
    }
  }

  // get one item by key from storage
  get(key) {
    if (this.localStorageSupported) {
      return localStorage.getItem(key);
    } else {
      return {};
    }
  }

  // remove value from storage
  remove(key) {
    if (this.localStorageSupported) {
      localStorage.removeItem(key);
    }
  }

  // clear storage (remove all items from it)
  clear() {
    if (this.localStorageSupported) {
      localStorage.clear();
    }
  }
}

const ls = new StorageHelper();
// Add Access Token
export function AddDataToLocalStorage(dataKey, dataValue) {
  ls.add(dataKey, JSON.stringify(dataValue));
}

// Get current user
export function GetCurrentStorage(dataKey) {
  const currentData = ls.get(dataKey);
  if (currentData && currentData !== "undefined") {
    return JSON.parse(currentData);
  }
  return null;
}
