import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import UsersList from "../users/UsersList";
import Messages from "../messages/Messages";
import {
  fetchAgentSeedMapping,
  fetchUsersList,
} from "../../actions/initialize-app.action";
import { initLoadingUser } from "../../actions/update-users-list.action";
import "./MainLayout.css";

class MainLayout extends Component {
  componentDidMount() {
    this.chatListSetInterval(this.fetchAllChatList, 15000);
  }
  chatListSetInterval = (func, interval) => {
    this.props.initLoadingUser();
    this.fetchAllChatList();
    return setInterval(func, interval);
  };

  fetchAllChatList = () => {
    this.props.fetchAgentSeedMapping();
    this.props.fetchUsersList();
  };

  render() {
    return (
      <div className="app">
        <UsersList />
        <Messages />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  initialized: state.init.initialized,
  activeUser: state.activeUser,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchAgentSeedMapping,
  initLoadingUser,
  fetchUsersList,
})(MainLayout);
