import { combineReducers } from "redux";

import init from "./initReducer";
import auth from "./authReducer";
import activeUser from "./activeUserReducer";
import usersList from "./usersReducer";
import messages from "./messagesReducer";
import newMessageCount from "./newNessageCountReducer";
import seedUsers from "./seedUsersReducer";

export default combineReducers({
  init,
  auth,
  activeUser,
  usersList,
  messages,
  newMessageCount,
  seedUsers,
});
