import React, { useState, useEffect } from "react";
import { storage } from "../firebase";
import Spinner from "../components/dashboard/Spinner";
const useFirebaseFileLoader = (firebaseFilePath, isImageUrl) => {
  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (firebaseFilePath) {
      setLoading(<Spinner />);
      storage
        .child(firebaseFilePath)
        .getDownloadURL()
        .then((completeUrlFromFirebase) => {
          if (isImageUrl) {
            const image = new Image();
            image.onload = () => {
              setFileUrl(completeUrlFromFirebase);
              setLoading(null);
            };
            image.src = completeUrlFromFirebase;
          } else {
            setFileUrl(completeUrlFromFirebase);
          }
        })
        .catch((err) => {
          console.log(err);
          // eslint-disable-next-line no-underscore-dangle
          if (err && err.code_ && err.code_ === "storage/object-not-found") {
            setFileUrl("");
            // throw new Error("Profile Image not found.");
          }
        });
    } else {
      setError(true); // If there is no profile image path.
    }
  }, [firebaseFilePath]);
  return [fileUrl, loading, error];
};

export default useFirebaseFileLoader;
