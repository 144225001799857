import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import { sendFileUpload } from "../../actions/create-new-message.action";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: "none",
    borderRadius: "8px",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    height: 270,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    background: "#2bb793",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 50,
    padding: "0 15px",
    marginTop: "8px",
    marginLeft: "3px",
    marginRight: "3px",
    outline: "none",
  },
  label: {
    fontWeight: 600,
    color: "white",
  },
}));

const NewMessageFileInput = ({
  isOpen,
  onClose,
  handleFileInputChange,
  handleCaptionChange,
  status,
  caption,
  isMediaFile,
  handleFileInputSend,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="mediaupload">
          <input
            disabled={isMediaFile ? true : false}
            type="file"
            onChange={handleFileInputChange}
          />
          <div>
            <p>{status}</p>
          </div>
          <input
            type="text"
            placeholder="type a message"
            value={caption}
            onChange={handleCaptionChange}
          />
          <Button
            disabled={isMediaFile ? false : true}
            onClick={() => handleFileInputSend()}
            classes={{
              root: classes.button,
              label: classes.label,
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewMessageFileInput;
