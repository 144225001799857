import React, { Component } from "react";
import { connect } from "react-redux";
import UserChat from "./UserChat";
import Spinner from "../dashboard/Spinner";
import "./UsersList.css";

class UsersListUnread extends Component {
  renderUnreadList = () => {
    if (this.props.usersList.loadingUsers) {
      return <Spinner />;
    }
    if (
      !this.props.usersList.loadingUsers &&
      this.props.users &&
      this.props.users.length > 0
    ) {
      return this.props.users.map(
        (user) => {
          let chatId = `${user?.userId}_${user?.to_user_id}`;
          return this.props.newMessageCount[chatId] === 0 && (
            <UserChat key={chatId} chat={user} />
          )
        }
      );
    }
  };
  render() {
    return (
      <div className="sidebar__chat">{this.renderUnreadList()}</div>
    );
  }
}

const mapStateToProps = (state) => ({
  newMessageCount: state.newMessageCount,
  activeUser: state.activeUser,
  usersList: state.usersList,
  users: state.usersList && state.usersList.users,
  lastCreatedAtTime: state.usersList && state.usersList.lastCreatedAtTime,
});

export default connect(mapStateToProps, {})(UsersListUnread);
