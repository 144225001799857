import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import setUserTyping from "../../actions/set-user-typing.action";
import { sendNewMessage } from "../../actions/create-new-message.action";
import { setReplyToTagMessage } from "../../actions/update-message-list.action";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { IconButton } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import NewMessageFileInput from "./NewMessageFileInput";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import {
  setFAQsOpen,
  sendFileUpload,
} from "../../actions/create-new-message.action";
import ReactHtmlParser from "react-html-parser";
import Linkify from "react-linkify";

class NewMessageInput extends Component {
  static propTypes = {
    onType: PropTypes.func.isRequired,
    onNewMessage: PropTypes.func.isRequired,
  };

  state = {
    message: "",
    open: false,
    emojiPanel: false,
    openFileUploadModal: false,
    mediaFile: null,
    caption: "",
    status: "Please upload File.",
  };
  onChange = (e) => {
    this.setMessageText(e.target.value);
  };
  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.sendMessage(e);
    }
  };
  setMessageText = (messageText) => {
    this.setState({ message: messageText }, () =>
      this.props.onType(this.state.message)
    );
  };
  sendMessage = (e) => {
    e.preventDefault();
    if (this.state.message !== "" && this.state.message.trim() !== "") {
      let messageData = { text: this.state.message };
      if (this.props.messages && this.props.messages.replyToTagMessage) {
        messageData.replyKey = this.props.messages.replyToTagMessage.key;
      }
      this.props.onNewMessage(messageData);
    }
    this.closeReplyTagMessagePanel();
    this.setMessageText("");
    this.setState({ emojiPanel: false });
  };
  setOpen = (open) => {
    this.setState({ emojiPanel: false });
    this.setState({ open: open });
  };
  onClose = () => this.setOpen(false);

  handleFAQsClick = () => {
    this.props.setFAQsOpen();
  };

  setOpenFileUploadModal = (open) => {
    this.setState({
      emojiPanel: false,
      mediaFile: null,
      caption: "",
      status: "Please upload File.",
      openFileUploadModal: open,
    });
  };
  onCloseFileUploadModal = () => this.setOpenFileUploadModal(false);

  handleFileInputChange = (e) => {
    if (e.target.files[0]) {
      this.setState({
        mediaFile: e.target.files[0],
        status: "File uploaded successfully!",
      });
    }
  };
  handleCaptionChange = (e) => {
    this.setState({ caption: e.target.value });
  };

  handleFileInputSend = () => {
    console.log("calling from comp");
    this.props.sendFileInput({
      mediaFile: this.state.mediaFile,
      caption: this.state.caption,
    });
    this.setState({
      mediaFile: null,
      caption: "",
      status: "Please upload File.",
      openFileUploadModal: false,
    });
  };

  handleJobsClick = () => {
    this.setState({ emojiPanel: false });
    this.setOpen(true);
  };
  onEmojiClick = (event, emojiObject) => {
    let newMessage = this.state.message + emojiObject.emoji;
    this.setState({ message: newMessage });
  };

  emojiPanelOpenClose = () => {
    this.setState({ emojiPanel: !this.state.emojiPanel });
  };

  closeReplyTagMessagePanel = () => {
    this.props.setReplyToTagMessage(null);
  };
  render() {
    const selectedChatUserDetails =
      this.props.messages && this.props.messages.selectedChatUserDetails;
    const replyToTagMessage =
      this.props.messages && this.props.messages.replyToTagMessage;
    return (
      <div className="message__footer">
        {selectedChatUserDetails && selectedChatUserDetails.length > 0 && (
          <>
            <IconButton onClick={this.emojiPanelOpenClose}>
              <InsertEmoticonIcon />
            </IconButton>
            {this.state.emojiPanel && (
              <div className="message__footerEmoji">
                <Picker
                  onEmojiClick={this.onEmojiClick}
                  skinTone={SKIN_TONE_MEDIUM_DARK}
                />
              </div>
            )}
            <IconButton onClick={() => this.setOpenFileUploadModal(true)}>
              <AttachFileIcon />
            </IconButton>
            <NewMessageFileInput
              isOpen={this.state.openFileUploadModal}
              onClose={this.onCloseFileUploadModal}
              handleFileInputChange={this.handleFileInputChange}
              handleCaptionChange={this.handleCaptionChange}
              status={this.state.status}
              caption={this.state.caption}
              isMediaFile={!!this.state.mediaFile}
              handleFileInputSend={this.handleFileInputSend}
            />
            <form>
              <input
                value={this.state.message}
                onChange={this.onChange}
                onKeyPress={this.onKeyPress}
                placeholder="Send a meassage.."
              />
              <IconButton onClick={this.sendMessage}>
                <SendIcon />
              </IconButton>
            </form>
            {replyToTagMessage && (
              <div className="message__reply">
                <div className="message__replyText">
                  <Linkify>
                    {ReactHtmlParser(replyToTagMessage.message)}
                  </Linkify>
                </div>
                <IconButton onClick={this.closeReplyTagMessagePanel}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  activeUser: state.activeUser,
});
export default connect(mapStateToProps, (dispatch) => ({
  onType: (text) => dispatch(setUserTyping({ typing: !!text.length })),
  onNewMessage: (text) => dispatch(sendNewMessage(text)),
  setReplyToTagMessage: (message) => dispatch(setReplyToTagMessage(message)),
  sendFileInput: (message) => dispatch(sendFileUpload(message)),
  setFAQsOpen: () => dispatch(setFAQsOpen()),
}))(NewMessageInput);
