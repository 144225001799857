import { USER_LOADED, CHANGE_USER_PROFILE_IMAGE } from "../constants/types";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")),
  profileUrl: JSON.parse(localStorage.getItem("profileUrl")),
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        user: payload,
      };
    case CHANGE_USER_PROFILE_IMAGE:
      return {
        ...state,
        profileUrl: payload,
      };
    default:
      return state;
  }
};
