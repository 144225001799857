import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";
import Linkify from "react-linkify";

class MessageTagReply extends Component {
  state = {
    replyTo: null,
  };

  componentDidMount() {
    let messageList = this.props.messages && this.props.messages.messageList;
    if (messageList && this.props.replyMsgKey) {
      let repliedMessage = _.find(messageList, { key: this.props.replyMsgKey });
      this.setState({ replyTo: repliedMessage });
    }
  }

  render() {
    const { chat, userName, replyMsgKey } = this.props;
    return (
      <div className="replyMessage" onClick={this.onMsgClick}>
        <div className="replyName">
          {this.state.replyTo && chat.source === 1 ? "You" : userName}
        </div>
        <div className="replyText">
          {this.state.replyTo && (
            <Linkify>{ReactHtmlParser(this.state.replyTo.message)}</Linkify>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages,
});

export default connect(mapStateToProps)(MessageTagReply);
