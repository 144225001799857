import { AUTH_ERROR, LOGIN_SUCCESS, LOGOUT } from "../constants/types";

const initialState = {
  token: localStorage.getItem("userToken"),
  isAuthenticated:
    localStorage.getItem("userToken") !== null
      ? localStorage.getItem("isAuthenticated")
      : false,
  loading: true,
  user: null,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        token: payload.key,
        isAuthenticated: true,
        loading: false,
      };

    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    default:
      return state;
  }
}
