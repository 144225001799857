import { CLAP_LEVELS, CLAP_ICONS, CLAP_COLORS } from "../constants/types";

export const isMobile = () => {
  const isMobileView = navigator.userAgent
    .toLowerCase()
    .match(/android|blackBerry|iPhone|iPad|iPod|mini|iemobile|wpdesktop/i);
  return Boolean(isMobileView);
};

export const dateFormat = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let todayDateFunction = new Date();
  let newDateFunction = new Date(date);
  let todayDate = todayDateFunction.getDate();
  let currentMonth = todayDateFunction.getMonth();
  let currentYear = todayDateFunction.getFullYear();
  let newDate = newDateFunction.getDate();
  let newMonth = newDateFunction.getMonth();
  let newYear = newDateFunction.getFullYear();
  let hours = newDateFunction.getHours();
  let minutes = newDateFunction.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  if (
    todayDate === newDate &&
    currentMonth === newMonth &&
    currentYear === newYear
  ) {
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  } else if (newDate === todayDate - 1) {
    let strTime = `yesterday, ${hours}:${minutes} ${ampm}`;
    return strTime;
  } else {
    let strTime = `${monthNames[newMonth]} ${newDate}, ${hours}:${minutes} ${ampm}`;
    return strTime;
  }
};

export const sortBy = (prop) => {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};

export const generateClapsBadge = (clapCount) => {
  if (clapCount) {
    const clap = CLAP_LEVELS.find(({ value }) => clapCount >= value);
    return CLAP_ICONS[clap?.level];
  }
  return null;
};

export const generateClapsColors = (clapCount) => {
  const clap = CLAP_LEVELS.find(({ value }) => clapCount >= value);
  return CLAP_COLORS[clap?.level];
};
export const generateClapsLevel = (clapCount) => {
  const clap = CLAP_LEVELS.find(({ value }) => clapCount >= value);
  return clap?.level;
};

