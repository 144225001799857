import mixpanel from "mixpanel-browser";
import APIConfig from "./APIConfig";

mixpanel.init(APIConfig.MIX_PANEL_KEY);

const actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

const Mixpanel = actions;

export default Mixpanel;
